.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	img {
		max-width: 150px;
	}
}
