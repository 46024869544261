@use './sass/mixins';
@use './sass/variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-top: 54px;
	font-size: 15px;
	@media screen and (min-width: variables.$tablet-lg) {
		padding-top: 75px;
		font-size: 18px;
	}
}

*,
::after,
::before {
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	line-height: 1.1;
}
h1 {
	font-size: 22px;
	@media screen and (min-width: variables.$tablet-lg) {
		font-size: 30px;
	}
}
h2 {
	font-size: 19px;
	@media screen and (min-width: variables.$tablet-lg) {
		font-size: 22px;
	}
}
h3 {
	font-size: 17px;
	font-weight: 700;
	@media screen and (min-width: variables.$tablet-lg) {
		font-size: 20px;
	}
}
p {
	line-height: 1.6;
}
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
a {
	text-decoration: none;
}
img {
	max-width: 100%;
}
.container {
	width: 100%;
	padding: 0 15px;
	@media screen and (min-width: variables.$desktop-sm) {
		width: 1170px;
		margin: 0 auto;
	}
}
.success {
	color: green;
	align-self: flex-end;
}
.error {
	color: red;
	align-self: flex-end;
}
